/*
 * @Description:
 * @Version: 2.0
 * @Autor: jl.g
 * @Date: 2022-08-18 13:59:22
 * @LastEditors: YYH
 * @LastEditTime: 2024-10-08 11:04:19
 */
import React, { useRef, useEffect, useState } from "react";
import styles from "./index.module.less";
import { useHistory } from "react-router-dom";
import { isMobileDevice, isRunningInElectronApp } from "@/utils";
import WinClose from "../../assets/imgs/head/win-close.svg";
import WinMin from "../../assets/imgs/head/win-min.svg";
import WinMax from "../../assets/imgs/head/win-max.svg";
import WinRetract from "../../assets/imgs/head/win-retract.svg";
import MacClose from "../../assets/imgs/head/mac-close.svg";
import MacMin from "../../assets/imgs/head/mac-min.svg";
import MacMax from "../../assets/imgs/head/mac-max.svg";
import MacRetract from "../../assets/imgs/head/mac-retract.svg";
const HeadColumn = (props) => {
  // 判断移动端还是pc
  // true 移动端
  // false pc
  const deviceType = useRef(isMobileDevice());
  const [isElectron, setIsElectron] = useState(false);
  const [isMax, setIsMax] = useState(false);
  const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
  /**
   * @description: 收起
   * @return {*}
   */
  const handleMin = () => {
    window.ipcRenderer && window.ipcRenderer.send("minimize-main");
  };
  /**
   * @description: 放大缩小
   * @return {*}
   */
  const handleMax = async () => {
    if (window.ipcRenderer) {
      const res = await window.ipcRenderer.invoke("maximize-main");
      setIsMax(res);
    }
  };
  /**
   * @description: 关闭
   * @return {*}
   */
  const handleClose = () => {
    window.ipcRenderer && window.ipcRenderer.send("close-main");
  };
  useEffect(() => {
    if (isRunningInElectronApp()) {
      setIsElectron(true);
      console.log("当前在 Electron 应用中打开");
    } else {
      setIsElectron(false);
      console.log("当前在浏览器中打开");
    }
    console.log(deviceType.current, "deviceType", isMac);
  }, []);
  return (
    <div className={styles.header}>
      {/* 移动端和网页打开不显示 */}
      {!deviceType.current ? (
        <>
          {isElectron ? (
            <div className="operate-container">
              {isMac ? (
                <div className="max-operate">
                  <div className="close" onClick={handleClose}>
                    <img src={MacClose} alt="关闭" />
                  </div>
                  <div className="retract" onClick={handleMin}>
                    <img src={MacRetract} alt="收起" />
                  </div>
                  {isMax ? (
                    <div className="max" onClick={handleMax}>
                      <img src={MacMin} alt="缩小" />
                    </div>
                  ) : (
                    <div className="max" onClick={handleMax}>
                      <img src={MacMax} alt="放大" />
                    </div>
                  )}
                </div>
              ) : (
                <div className="win-operate">
                  <div onClick={handleMin}>
                    <img src={WinRetract} alt="收起" />
                  </div>
                  {isMax ? (
                    <div onClick={handleMax}>
                      <img src={WinMin} alt="缩小" />
                    </div>
                  ) : (
                    <div onClick={handleMax}>
                      <img src={WinMax} alt="放大" />
                    </div>
                  )}
                  <div onClick={handleClose}>
                    <img src={WinClose} alt="关闭" />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default HeadColumn;
